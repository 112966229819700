body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: gray;
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  display: flex;
  flex-direction: column;
  padding: 5rem;
}

.title {
  text-transform: uppercase;
  align-self: center;
  margin-bottom: 8rem;
  font-size: 4vw;

  animation: slide 1s;
}

.imgContainer {
  align-self: center;
  max-width: 50vw;
  margin: 4rem 0;
}

img {
  width: 100%;
  height: auto;
}


.scrollbar-track {
  background-color: transparent !important;
}

/* .scrollbar-track-y {
  width: 12px !important;
} */


/* .scrollbar-thumb {
  width: 12px !important;
  background-color: black !important;
  border-radius: 0 !important;
} */


.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}


.text {
  align-self: center;
  max-width: 40rem;
}


/* .App,
.App * {
  animation: appear 1s;
} */

@keyframes slide {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}